export const prerender = false;

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { PUBLIC_GITHUB_SHA, PUBLIC_SENTRY_DSN } from "$env/static/public";

if (PUBLIC_SENTRY_DSN != "") {
	Sentry.init({
		dsn: PUBLIC_SENTRY_DSN,
		release: PUBLIC_GITHUB_SHA || "unknown",
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

/** @type {import('./$types').LayoutLoad} */
export function load({ url }) {
	return {
		og_url: url.origin + url.pathname,
		is_index: url.pathname == "/",
	};
}
